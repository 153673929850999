import { userTimingArray } from "./filter-list";
import {
  capitalizeFirstLetterAfterPrefix,
  isEmptyBeacon,
  metricPrefix,
  RawUserTimingData,
} from "./beacon-util";
import { sendUserTimingsToBeacon } from "./user-timing";

import { Metric } from "web-vitals";
import { trackingEndpointLogic } from "./tracking";

const webVitalsToMetricMap = {
  FCP: "firstContentfulPaint",
  LCP: "largestContentfulPaint",
  FID: "firstInputDelay",
  CLS: "cumulativeLayoutShift",
  INP: "interactionToNextPaint",
};

let collectedBeaconServiceMetrics: RawUserTimingData = {};

/**
 *
 *
 */
export function performanceBeaconTracker(metric: Metric) {
  /*                                                                  */
  const metricName = webVitalsToMetricMap[metric.name] || metric.name;
  const duration: number = metric.value;
  const metricNamePascal: string = capitalizeFirstLetterAfterPrefix(metricName);
  const prefixedMetricName = `${metricPrefix}${metricNamePascal}`;

  /*                           */
  if (duration <= 0) {
    return;
  }
  trackingEndpointLogic({ [prefixedMetricName]: duration });
  /*                               */
  if (userTimingArray.includes(prefixedMetricName)) {
    collectedBeaconServiceMetrics[prefixedMetricName] = duration;
  }
}

/**
 *
 */
export function flushQueue() {
  if (isEmptyBeacon(collectedBeaconServiceMetrics)) {
    return;
  }
  sendUserTimingsToBeacon(collectedBeaconServiceMetrics);
  collectedBeaconServiceMetrics = {};
}

export function isSafari() {
  /*                                                            */
  const userAgent = navigator.userAgent;
  /*                                                                                    */
  /*                                                                                            */
  return (
    userAgent.includes("Safari") &&
    userAgent.includes("AppleWebKit") &&
    !userAgent.includes("Chrome") &&
    !userAgent.includes("CriOS") &&
    !userAgent.includes("Edg")
  );
}
