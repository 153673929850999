import {DataContainer, submitEvent, submitMerge} from "@otto-ec/tracking-bct";
import {capitalizeAndPrefixKeys, isEmptyBeacon, isToggleActive, RawRumData, RawUserTimingData,} from "./beacon-util";
import {trackingEventArray, trackingPIArray} from "./filter-list";

/**
 *
 *
 *
 *
 */
export function trackingEndpointLogic(
  rumData: RawRumData | RawUserTimingData,
): void {
  const AddPIMetricList: RawRumData = filterFor(rumData, trackingPIArray);
  const AddEventMetricList: RawRumData = filterFor(rumData, trackingEventArray);
  if (!isEmptyBeacon(AddPIMetricList)) {
    trackingAddMergeToPageImpression(capitalizeAndPrefixKeys(AddPIMetricList));
  }
  if (!isEmptyBeacon(AddEventMetricList)) {
    trackingAddEvent(capitalizeAndPrefixKeys(AddEventMetricList));
  }
}

const submitMergeOnLateDefault = "quality_submitMerge_onLate_default";

/**
 *
 *
 *
 *
 */
export function trackingAddMergeToPageImpression(
  metricData: RawRumData | RawUserTimingData
): void {
  if (!isEmptyBeacon(metricData)) {
    const strategy: "default" | "discard" = isToggleActive(
      submitMergeOnLateDefault,
    )
      ? "default"
      : "discard";
    submitMerge(convertToDataContainer(metricData), [], strategy);
  }
}

/**
 *
 *
 *
 *
 */
export function trackingAddEvent(
  metricData: RawRumData | RawUserTimingData
): void {
  if (!isEmptyBeacon(metricData)) {
    submitEvent(convertToDataContainer(metricData));
  }
}

/**
 *
 *
 */
function convertToDataContainer(
  metricData: RawRumData | RawUserTimingData,
): DataContainer {
  const dataContainer: DataContainer = {};
  Object.entries(metricData).forEach(([key, value]) => {
    dataContainer[key] = [value.toString()];
  });
  return dataContainer;
}

/**
 *
 *
 *
 */
function filterFor(metricData: RawRumData, filterList: string[]): RawRumData {
  const filteredMetricData: RawRumData = {};
  Object.entries(metricData).forEach(([key, value]) => {
    if (filterList.includes(key)) {
      filteredMetricData[key] = value;
    }
  });
  return filteredMetricData;
}
