import {onCLS, onFCP, onFID, onINP, onLCP} from "web-vitals";
import type {GlobalEvents} from "@otto-ec/global-resources";

import {flushQueue, isSafari, performanceBeaconTracker,} from "./quality-vital-metrics";

onFID(performanceBeaconTracker);
onLCP(performanceBeaconTracker);
onFCP(performanceBeaconTracker);
onCLS(performanceBeaconTracker);
onINP(performanceBeaconTracker);

/*                                                                             */
if (isSafari()) {
    /*                                                              */
    document.addEventListener("DOMContentLoaded", () => {
        window.o_global
            .events<GlobalEvents>()
            .on("assets.events.allJavascriptLoaded", flushQueue);
    });
    /*                                                             */
    window.addEventListener("pagehide", flushQueue);
    /*                                                                          */
    window.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
            flushQueue();
        }
    });
} else {
    window.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
            flushQueue();
        }
    });
}
