import * as util from "./beacon-util";
import {
  IMetric,
  METRIC_TYPE,
  normalizeBrowser,
  RawUserTimingData,
  readBeaconServicePath,
  UserTimingData,
} from "./beacon-util";
import { trackingEndpointLogic } from "./tracking";
import { userTimingArray } from "./filter-list";

export const toggleNameUserTimings = "SCALE_SendUserTimings";

/**
 *
 */
export function mergeRumData(metricData: RawUserTimingData): UserTimingData {
  const {
    o_global: { device },
    o_util: { misc },
  } = window;
  try {
    return {
      rumMetrics: <IMetric[]>(
        (<unknown>util.filterByAllowedNames(metricData, userTimingArray))
      ),
      rum_deviceType: device.type,
      rum_deviceOrientation: device.orientation.isLandscape()
        ? "landscape"
        : "portrait",
      rum_supportsTouch: device.isTouchable,
      deviceOS: device.os,
      deviceBrowser: normalizeBrowser(device.browser),
      pageCluster: misc.getPagecluster(),
      beaconType: "userTiming",
      requestTime: Date.now(),
    };
  } catch (e) {
    /*                    */
  }
}

export function readBeaconServicePathUserTiming(): string {
  return readBeaconServicePath() + "userTiming";
}

/**
 */
export function sendUserTimingsToBeacon(metricData: RawUserTimingData) {
  util.sendValidBeacon(
    mergeRumData(metricData),
    readBeaconServicePathUserTiming(),
    toggleNameUserTimings,
    METRIC_TYPE.userTiming
  );
}

/**
 */
export function sendBeacon(metricData: RawUserTimingData): void {
  sendUserTimingsToBeacon(metricData);
  trackingEndpointLogic(metricData);
}
